<template>
    <!-- 主内容 -->
    <div class="course-main">
        <div class="course-main-wrap">
            <!-- 管理员 -->
            <div class="course-main-header flex y-center x-between mb24">
                <div class="course-main-header-left flex y-center">
                    <div class="header-desc mr40 f18">{{ schoolName }}</div>
                    <div class="header-bread flex y-center">
                        <div v-if="!isMobile" class="header-bread-text f14 mr8">当前位置：</div>
                        <wj-breadcrumb separator-class="wj-icon-arrow-right">
                            <!-- <wj-breadcrumb-item :to="{ path: '/' }"
                                        >首页</wj-breadcrumb-item
                                    > -->
                            <wj-breadcrumb-item :to="{ path: '/' }">首页</wj-breadcrumb-item>
                            <wj-breadcrumb-item v-if="type == 1" :to="{ path: '/admin/course' }"
                                >课程管理</wj-breadcrumb-item
                            >
                            <wj-breadcrumb-item
                                v-else
                                :to="{
                                    path: '/course/list',
                                    query: { id: educateeId, name: schoolName },
                                }"
                                >{{ schoolName }}</wj-breadcrumb-item
                            >
                            <wj-breadcrumb-item>{{ courseName }}</wj-breadcrumb-item>
                        </wj-breadcrumb>
                    </div>
                </div>
            </div>

            <div class="course-main-content">
                <div class="title">课程目录</div>
                <div class="course-list" v-loading="loading">
                    <template v-if="isMobile && catalogList.length">
                        <div
                            class="course-item-m flex y-center"
                            v-for="(item, index) in catalogList"
                            :key="index"
                            @click="gotoVideo(item.episodeId)"
                        >
                            <wj-image :src="require('@/assets/images/play.png')"></wj-image>
                            <span class="course-title-m">{{ item.title }}</span>
                        </div>
                    </template>
                    <template v-if="!isMobile && catalogList.length">
                        <draggable
                            :disabled="!isAdmin"
                            v-model="catalogList"
                            @end="onEnd"
                            chosen-class="chosen"
                            force-fallback="true"
                            group="people"
                            animation="200"
                        >
                            <transition-group>
                                <div
                                    class="course-item"
                                    :style="{ cursor: !isAdmin ? 'default' : 'move' }"
                                    v-for="item in catalogList"
                                    :key="item.episodeId"
                                >
                                    <div class="flex y-center">
                                        <wj-image
                                            :src="require('@/assets/images/play.png')"
                                        ></wj-image>
                                        <span class="course-title">{{ item.title }}</span>
                                    </div>
                                    <div class="operation-list y-center">
                                        <wj-button
                                            type="primary"
                                            @click="gotoVideo(item.episodeId)"
                                        >
                                            观看视频
                                        </wj-button>
                                        <wj-dropdown
                                            v-if="isAdmin"
                                            placement="bottom-end"
                                            size="mini"
                                            trigger="hover"
                                            :show-timeout="0"
                                            @command="(type) => handleDropDown(type, item)"
                                        >
                                            <i class="iconfont icon-gengduo"></i>
                                            <wj-dropdown-menu
                                                slot="dropdown"
                                                class="more-dropdown-menu"
                                            >
                                                <template v-if="type">
                                                    <wj-dropdown-item
                                                        class="flex y-center"
                                                        :command="'edit'"
                                                        ><i class="iconfont icon-zhongmingming"></i
                                                        >修改</wj-dropdown-item
                                                    >
                                                    <wj-dropdown-item
                                                        class="flex y-center"
                                                        :command="'delete'"
                                                        ><i class="iconfont icon-shanchu"></i
                                                        >删除</wj-dropdown-item
                                                    >
                                                </template>
                                                <template v-else>
                                                    <wj-dropdown-item
                                                        class="flex y-center"
                                                        :command="'exam'"
                                                        >上传试题链接</wj-dropdown-item
                                                    >
                                                </template>
                                            </wj-dropdown-menu>
                                        </wj-dropdown>
                                    </div>
                                </div>
                            </transition-group>
                        </draggable>
                    </template>
                    <template v-if="catalogList.length < 1 && !loading">
                        <div class="empty-area">
                            <wj-image :src="require('@/assets/images/empty-data.png')"></wj-image>
                            <span class="sign">暂未上传课程</span>
                        </div></template
                    >
                </div>

                <div class="btn-option" v-if="isAdmin">
                    <wj-button type="primary" @click="gotoUrl()"> 上传课程 </wj-button>
                </div>
            </div>
        </div>
        <wj-dialog
            custom-class="add-container"
            :visible.sync="showDialogAdd"
            width="432px"
            title="上传试题链接"
            :show-close="false"
            :close-on-click-modal="false"
        >
            <wj-form ref="examFormRef" :model="examFormData" label-width="100px">
                <wj-form-item label="标题：">
                    <wj-input
                        disabled
                        v-model="examFormData.title"
                        placeholder="请输入标题"
                    ></wj-input>
                </wj-form-item>
                <wj-form-item label="试题链接：">
                    <wj-input
                        type="textarea"
                        v-model="examFormData.url"
                        placeholder="请输入链接"
                    ></wj-input>
                </wj-form-item>
                <wj-form-item class="btn-options">
                    <wj-button @click="showDialogAdd = false">取消</wj-button>
                    <wj-button type="primary" @click="addExam">确定</wj-button>
                </wj-form-item>
            </wj-form>
        </wj-dialog>
        <wj-dialog
            custom-class="add-container"
            :visible.sync="showDialogEdit"
            width="432px"
            title="修改课程"
            :show-close="false"
            :close-on-click-modal="false"
        >
            <wj-form ref="courseFormRef" :model="courseFormRef" label-width="100px">
                <wj-form-item label="标题：">
                    <wj-input v-model="courseFormRef.title" placeholder="请输入标题"></wj-input>
                </wj-form-item>
                <wj-form-item label="简介：">
                    <wj-input
                        type="textarea"
                        v-model="courseFormRef.introduction"
                        placeholder="请输入简介"
                    ></wj-input>
                </wj-form-item>
                <wj-form-item class="btn-options">
                    <wj-button @click="showDialogEdit = false">取消</wj-button>
                    <wj-button type="primary" @click="updateCourse">确定</wj-button>
                </wj-form-item>
            </wj-form>
        </wj-dialog>
    </div>
</template>

<script>
import {
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Dialog,
    Form,
    FormItem,
    Image,
    Input,
    Pagination,
    Dropdown,
    DropdownMenu,
    DropdownItem,
} from '@wenjuan/ui';
import draggable from 'vuedraggable';
import {
    httpPutAddExam,
    httpPatchAdminCourseEpisode,
    httpDeleteAdminCourseEpisode,
    httpPatchAdminCourseEpisodeOrder,
    httpGetExamInfo,
} from '@/http/admin/index.js';
import { httpGetCourseEpisodeList, httpGetCourseInfo } from '@/http/user/index.js';
export default {
    name: 'course',
    components: {
        'wj-form': Form,
        'wj-form-item': FormItem,
        'wj-breadcrumb': Breadcrumb,
        'wj-breadcrumb-item': BreadcrumbItem,
        'wj-button': Button,
        'wj-dialog': Dialog,
        'wj-image': Image,
        'wj-input': Input,
        'wj-pagination': Pagination,
        'wj-dropdown': Dropdown,
        'wj-dropdown-menu': DropdownMenu,
        'wj-dropdown-item': DropdownItem,
        draggable,
    },
    data() {
        return {
            showDialogAdd: false,
            showDialogEdit: false, //编辑课程
            catalogList: [],
            type: null, //true:课程管理操作
            courseFormRef: {
                title: '',
                brief: '',
                episodeId: '',
            },
            examFormData: {
                url: '',
                title: '',
                episodeId: '',
            },
            courseId: '',
            loading: false,
            schoolName: '',
            courseName: '',
            orderList: [],
            educateeId: '',
            isMobile: false,
        };
    },
    created() {
        if (
            navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
        ) {
            this.isMobile = true;
        }
        this.type = this.$route.path.includes('/admin') ? 1 : 0;
        this.courseId = this.$route.query.cid;
        this.educateeId = this.$route.query.id;
        this.getCatalogList();
        this.getCourseInfo(this.educateeId, this.courseId);
    },
    mounted() {},
    computed: {
        isAdmin() {
            return this.$store.state.userType === 'ADMIN';
        },
    },
    methods: {
        //获取课程详情
        getCourseInfo(educateeId, courseId) {
            httpGetCourseInfo({ educateeId, courseId }).then((res) => {
                let { school, title } = res.data.data;
                this.schoolName = school;
                this.courseName = title;
            });
        },
        getCatalogList() {
            this.loading = true;
            httpGetCourseEpisodeList(this.courseId)
                .then((res) => {
                    this.loading = false;
                    this.catalogList = res.data.data;
                    this.orderList = this.catalogList.map((item) => {
                        return item.episodeId;
                    });
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        gotoUrl() {
            this.$router.push(`/course/manage?cid=${this.courseId}`);
        },
        gotoVideo(episodeId) {
            if (this.educateeId) {
                this.$router.push(
                    `/course/detail?cid=${this.courseId}&eid=${episodeId}&id=${this.educateeId}`
                );
            } else {
                this.$router.push(`/course/detail?cid=${this.courseId}&eid=${episodeId}`);
            }
        },
        handleDropDown(type, item) {
            if (type == 'exam') {
                httpGetExamInfo(this.educateeId, item.episodeId).then((res) => {
                    this.showDialogAdd = true;
                    this.examFormData.title = item.title;
                    this.examFormData.episodeId = item.episodeId;
                    this.examFormData.url = res.data.data.url || '';
                });
            } else if (type == 'edit') {
                this.showDialogEdit = true;
                let { episodeId, introduction, title } = item;
                this.courseFormRef = {
                    episodeId,
                    introduction,
                    title,
                };
            } else if (type == 'delete') {
                this.$confirm('确定删除此视频吗？', '删除视频', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                })
                    .then(() => {
                        this.showLoading();
                        httpDeleteAdminCourseEpisode(item.episodeId)
                            .then(() => {
                                this.hideLoading();
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                    duration: 2000,
                                });
                                this.getCatalogList();
                            })
                            .catch(() => {
                                this.hideLoading();
                            });
                    })
                    .catch(() => {});
            }
        },
        addExam() {
            let { url, episodeId } = this.examFormData;
            let educateeId = this.$route.query.id;
            let params = {
                url,
                episodeId,
                educateeId,
            };
            httpPutAddExam(params)
                .then(() => {
                    this.showDialogAdd = false;
                    this.$message({
                        type: 'success',
                        message: '上传成功',
                        duration: 2000,
                    });
                })
                .catch((res) => {
                    console.log(res);
                });
        },
        //更新课程集
        updateCourse() {
            let { episodeId, introduction, title } = this.courseFormRef;
            this.showLoading();
            httpPatchAdminCourseEpisode(episodeId, { introduction, title })
                .then(() => {
                    this.showDialogEdit = false;
                    this.hideLoading();
                    this.$message({
                        type: 'success',
                        message: '修改成功',
                        duration: 2000,
                    });
                    this.getCatalogList();
                })
                .catch(() => {
                    this.hideLoading();
                });
        },
        //拖拽结束
        onEnd() {
            console.log(
                '拖拽结束',
                this.catalogList.map((item) => {
                    return item.episodeId;
                })
            );
            let courseId = this.courseId;
            let orders = this.catalogList.map((item, index) => {
                return {
                    episodeId: item.episodeId,
                    order: index,
                };
            });
            let orderIds = this.catalogList.map((item) => {
                return item.episodeId;
            });
            if (orderIds.toString() == this.orderList.toString()) {
                return;
            }
            httpPatchAdminCourseEpisodeOrder(courseId, { orders }).then(() => {
                this.orderList = orderIds;
                this.$message({
                    message: '顺序修改成功',
                    type: 'success',
                    duration: 2000,
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.course-main {
    background-color: #f3f5f7;
    // 版心布局
    .course-main-wrap {
        width: 1330px;
        margin: 0 auto;
        @media (max-width: 640px) {
            width: 100%;
            text-align: center;
            padding: 0 24px;
            box-sizing: border-box;
        }
        .course-main-header {
            padding: 30px 0 5px 0;
            @media (max-width: 640px) {
                padding: 36px 0 0;
                margin-bottom: 30px;
            }
            .course-main-header-left {
                @media (max-width: 640px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .header-desc {
                    font-family: PingFangSC;
                    color: #3c3c3c;
                    @media (max-width: 640px) {
                        font-weight: bold;
                        line-height: 22px;
                        font-family: PingFangSC-Medium;
                        margin-bottom: 8px;
                        margin-right: 0;
                    }
                }
                .header-bread {
                    @media (max-width: 640px) {
                        .wj-breadcrumb {
                            line-height: 17px;
                            font-size: 12px;
                            ::v-deep .wj-breadcrumb__inner {
                                color: #8f8f8f;
                            }
                        }
                    }
                }
            }
            .course-main-header-left {
                color: #707070;
                @media (max-width: 640px) {
                    color: #8f8f8f;
                }
            }
            .course-main-header-right {
                color: #3385ff;
                cursor: pointer;
            }
        }
        .course-main-content {
            width: 1330px;
            border-radius: 4px;
            background-color: #fff;
            display: inline-block;
            @media (max-width: 640px) {
                width: 100%;
                box-sizing: border-box;
                padding: 0 20px;
            }
            .title {
                color: #3c3c3c;
                font-size: 18px;
                width: 100%;
                text-align: center;
                margin: 40px auto 33px;
                @media (max-width: 640px) {
                    font-size: 16px;
                    margin: 24px auto 0;
                    padding-bottom: 24px;
                    font-weight: bold;
                    border-bottom: 1px solid #f2f3f1;
                }
            }
            .course-list {
                border: 1px solid #f2f3f1;
                width: 1250px;
                margin: 0 auto 40px;
                min-height: 560px;
                @media (max-width: 640px) {
                    width: 100%;
                    min-height: 0;
                    border: 0;
                    margin-bottom: 0;
                }
                .empty-area {
                    width: 100%;
                    height: 560px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 640px) {
                        height: auto;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                    .wj-image {
                        width: 250px;
                        height: 150px;
                    }
                    .sign {
                        margin-top: 15px;
                        color: #666666;
                    }
                }
                .course-item {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .course-title {
                        color: #8f8f8f;
                        font-size: 14px;
                    }
                    &:nth-child(even) {
                        background: #fbfbfb;
                    }
                    &:nth-child(odd) {
                        background-color: #fff;
                    }
                    &.chosen {
                        box-shadow: 0px 0px 6px 0px rgb(150 148 148 / 20%);
                    }
                    .operation-list {
                        display: none;
                        .wj-button {
                            margin-right: 25px;
                        }
                    }
                    .wj-image {
                        width: 24px;
                        height: 24px;
                        margin-left: 70px;
                        margin-right: 40px;
                    }
                    .icon-gengduo {
                        font-size: 30px;
                        margin-right: 24px;

                        display: block;
                    }
                    &:hover {
                        .operation-list {
                            display: flex;
                        }
                    }
                }
                .course-item-m {
                    width: 100%;
                    height: 60px;
                    .wj-image {
                        width: 24px;
                        height: 24px;
                        margin-right: 20px;
                        flex-shrink: 0;
                    }
                    .course-title-m {
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        line-height: 17px;
                        color: #8f8f8f;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &:nth-child(even) {
                        background: #fbfbfb;
                    }
                    &:nth-child(odd) {
                        background-color: #fff;
                    }
                }
            }
            .btn-option {
                text-align: center;
                .wj-button {
                    margin: 40px auto 80px;
                    width: 136px;
                    height: 40px;
                }
            }
        }
        .course-main-footer {
            text-align: center;
            // 去掉边框
            .wj-pagination li,
            .wj-pagination button {
                background-color: #f3f5f7;
                font-size: 14px;
                color: #3c3c3c;
                font-family: PingFangSC;
                &:not(.active) {
                    border: 1px solid #f3f5f7;
                }
            }
            .wj-pagination input,
            .wj-pagination__jump {
                background-color: #f3f5f7;
                border-radius: 3px;
                font-size: 14px;
                color: #3c3c3c;
                font-family: PingFangSC;
            }
        }
    }
}
.add-container {
    .wj-textarea {
        ::v-deep .wj-textarea__inner {
            height: 150px;
        }
    }
    .btn-options {
        ::v-deep .wj-form-item__content {
            text-align: right;
        }
    }
}
.wj-dropdown-menu {
    .wj-dropdown-menu__item {
        padding: 5px 20px;
    }
}
</style>
